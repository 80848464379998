<ng-container *ngFor="let video of videoList; let i = index; trackBy: trackByPostId">
  <a
    class="video-card video-card-list cursor"
    [routerLink]="['/video', video.id]"
    >
    <!-- (click)="openDetailPage(video)" -->
    <div class="video-card-image">
      <a class="play-icon">
        <fa-icon [icon]="['fas', 'play-circle']" />
      </a>
      <a
        ><img
          class="img-fluid h-100"
          loading="lazy"
          data-src="{{ video?.thumbfilename }}"
          alt=""
          onerror="this.onerror=null;this.src='/assets/dummy/11.jpg';"
      /></a>
      <div class="time text-white">
        <fa-icon [icon]="['fas', 'clock']" [fixedWidth]="true" />
        {{ +video.videoduration | timeDurationWithSec }}
      </div>
    </div>
    <div class="video-card-body">
      <div class="video-title text-dark">
        <div
          [title]="video.albumname"
          placement="bottom"
          class="video-page font-16-px"
        >
          {{ video.albumname }}
        </div>
      </div>
      <div class="c-description-height video-description">
        <a
          [title]="stripTags(video.postdescription)"
          placement="bottom"
          >{{stripTags(video.postdescription)}}</a>
          <!-- [innerHTML]="video.postdescription | noSanitize" -->
        </div>
      <div class="video-page text-dark c-height">
        {{ video.firstname }}
      </div>
      <div class="video-view">
        <!-- <fa-icon [icon]="['fas', 'eye']" [fixedWidth]="true" />
        {{ video.viewcount }} views &nbsp; -->
        <fa-icon [icon]="['fas', 'calendar']" [fixedWidth]="true" />
        {{ video.postcreationdate | date | dateDay }}
      </div>
    </div>
  </a>

  <ng-container *ngIf="(i + 1) % 5 === 0 && advertisementDataList?.length">
    <ng-container *ngFor="let ad of advertisementDataList | randomAdvertisementUrl: i">
      <a [attr.href]="ad.link ? ad.link : null" [attr.target]="ad.link ? '_blank' : null"
      >
      <div class="img-preview rounded-3 w-100 h-100 advertisement-section my-2" [ngStyle]="{'background-image' : 'url(' + ad.imageUrl + ')'}">
        <img
          loading="lazy"
          data-src="{{ ad.imageUrl }}"
          class="w-100 h-100 rounded-3 image-render"
          alt="advertisement"
        />
      </div>
      </a>
    </ng-container>        
  </ng-container>
</ng-container>
