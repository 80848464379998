<div class="modal-header">
  <h6 class="text-start m-0">{{ title }}</h6>
  <fa-icon
    [icon]="['fas', 'xmark']"
    class="font-20-px"
    role="button"
    (click)="activeModal.close()"
  />
</div>

<div class="modal-body">
  <div class="d-flex align-items-start my-3">
    <div>
      <img
        loading="lazy"
        data-src="{{ profilePicName }}"
        onerror="this.onerror=null;this.src='/assets/images/avtar/placeholder-user.png';"
        class="w-56-px h-56-px rounded-4 me-2"
        alt="icon"
      />
    </div>
    <div class="w-100">
      <app-tag-user-input
        placeholder="Comment and Share"
        [value]="postMessageInputValue"
        [isShowMetaPreview]="false"
        (onDataChange)="onTagUserInputChangeEvent($event)"
      />
      <app-re-post-card [post]="post" />
    </div>
  </div>
</div>

<div class="modal-footer justify-content-end gap-2">
  <button
    type="button"
    class="btn btn-sm btn-danger"
    (click)="activeModal.close('cancel')"
  >
    {{ cancelButtonLabel }}
  </button>

  <button
    type="button"
    class="btn btn-sm btn-success"
    (click)="submit()"
    [disabled]="!sharePostData?.parentPostId"
  >
    {{ confirmButtonLabel }}
  </button>
</div>
