<div class="row">
    <div class="col-xl-3 col-sm-6 mb-3" *ngFor="let card of cardsData">
      <div class="card text-white bg-{{ card.backgroundColor }} o-hidden h-100 border-none">
        <div class="card-body">
          <div class="card-body-icon">
            <i class="fas fa-fw {{ card.icon }}"></i>
          </div>
          <div class="mr-5"><b>{{ card.count }}</b> {{ card.label }}</div>
        </div>
        <a class="card-footer text-white clearfix small z-1" href="#">
          <span class="float-left">View Details</span>
          <span class="float-right">
            <fa-icon [icon]="['fas', 'angle-right']" />
          </span>
        </a>
      </div>
    </div>
  </div>
  